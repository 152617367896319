import React from 'react';
// import '../style/skill.css';
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { faReact, faAppStore, faPython, faDigitalOcean, faAws, faGoogle, faDocker, faUbuntu, faJenkins, faGithub, faNodeJs, faHtml5, faAngular } from '@fortawesome/free-brands-svg-icons';
import FadeInSection from '../../FadeInSections'
import Chip from '@material-ui/core/Chip';

function ResumeSkill () {

  // enum colors
  const colors = {
    frameworks: '#f06529',
    nodejs: '#75B061',
    react: '#61dafb',
    tailwind: '#38BDF9',
    nextjs: '#000',
    expressjs: '#000',
    bs4: '#000',
    umijs: '#1890ff',
    angular: '#dd1b16',
    mongodb: '#4db33d',
    firebase: '#ffa611',
    postgresql: '#336791',
    mysql: '#00758f',
    python: '#306998',
    appstore: '#f8981d',
    server: "#90c5e7",
    numpy: '#4B73C9',
    pandas: '#140555',
    matplotlib: '#115278',
    pytorch: '#E74A2A',
    scipy: '#0151A0',
    sklearn: '#F09438',
    selenium: '#02AE00',
    puppeteer: '#08D29E',
    do: '#0169FF',
    aws: '#FF9900',
    gcp: '#3F7FE9',
    serverless: '#E7554E',
    heroku: '#625D9E',
    docker: '#228FE2',
    ubuntu: '#D34412',
    redis: '#CE2A1E',
    rabbitmq: '#F46100',
    jenkins: '#314B5C',
    github: '#000',
    jmeter: '#DB6C25',
    nginx: '#008F36',


  }

  const [data, setData] = React.useState([
    {
      id: 1,
      icon: faReact,
      iconColor: colors.react,
      title: "Full-Stack Development",
      desc: "I’ve gained hands-on experience with frontend, backend, and database technologies across various roles. While I specialize in building backends and databases for chatbots, I’ve also worked on a range of web applications. My favorite stack is MERN, but I’m always keen to explore new frameworks and tools.",
      // desc: placeholderDesc,
      tags: [
        ["Node.js", colors.nodejs, faNodeJs], // start of frameworks
        ["HTML", colors.frameworks, faHtml5],
        ["TailwindCSS", colors.tailwind],
        ["React", colors.react, faReact], // start of react frameworks
        ["NextJS", colors.nextjs],
        ["UmiJS", colors.umijs],
        ["Angular", colors.angular, faAngular],
        ["ExpressJS", colors.expressjs],
        ["MongoDB", colors.mongodb], // start of databases
        ["Firebase", colors.firebase],
        ["PostgreSQL", colors.postgresql],
        ["MySQL", colors.mysql],
      ]
    },
    {
      id: 2,
      icon: faPython,
      iconColor: colors.python,
      title: "Data Analytics & BI",
      desc: "I enjoy creating hobby projects that tell stories through data, like a gym capacity tracker or a tool for aggregating radio playlists. I’ve explored computer vision techniques like background subtraction and dabbled in machine learning, including labeling and training datasets for custom object detection in both school and personal projects.",
      tags: [
        ["BeautifulSoup", colors.bs4],
        ["Selenium", colors.selenium],
        ["Puppeteer", colors.puppeteer],
        ["YOLOv5", colors.pytorch],
        ["PyTorch", colors.pytorch],
        ["Pandas", colors.pandas],
        ["NumPy", colors.numpy],
        ["SciPy", colors.scipy],
        ["Matplotlib", colors.matplotlib],
        ["Sklearn", colors.sklearn],
      ]
    },
    {
      id: 3,
      icon: faServer,
      iconColor: colors.server,
      title: "System Design & Infra",
      desc: "Startups taught me to iterate quickly, while larger companies showed me how systems are designed at scale. I’ve also designed and set up systems for personal projects and within my own company. These experiences have sharpened my skills in building, maintaining, and optimizing systems and infrastructure.",
      tags: [
        ["DigitalOcean", colors.do, faDigitalOcean],
        ["AWS", colors.aws, faAws],
        ["GCloud", colors.gcp, faGoogle],
        ["Serverless", colors.serverless],
        ["Heroku", colors.heroku],
        ["Docker", colors.docker, faDocker],
        ["Ubuntu", colors.ubuntu, faUbuntu],
        ["Redis", colors.redis],
        ["RabbitMQ", colors.rabbitmq],
        ["Jenkins", colors.jenkins, faJenkins],
        ["GitHub", colors.github, faGithub],
        ["JMeter", colors.jmeter],
        ["Nginx", colors.nginx],
      ]
    }
  ]);
  return  (
    <div className="container" id="skills" style={{ paddingTop: '0.5em' }}>
        <div className="skills-container" style={{ maxWidth: "80%", paddingBottom: '0.5em'}}>
          <h2>Skills & Specialties</h2>
          {/* <div className="skills-grid"> */}
            { 
              data.map(item => (
                <div className="skill" key={item.id}>
                  <div 
                    style= {{
                      display: "flex",
                      flexDirection: "row",
                    }}>
                  <FontAwesomeIcon icon={item.icon} size="2x" style={{ maxHeight: "40px", maxWidth: "40px", marginRight: "0.5em" }} color={item.iconColor}/>
                  <h3 style={{ height: "28px", marginTop: "0.2em", marginBottom: '0' }}>{item.title}</h3>
                  </div>
                  <p style={{ fontSize: "70%" }}>{item.desc}</p>
                  <div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}>
                    {item.tags.map(tag => (
                      
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "5px",
                        minWidth: '0',
                      }}>
                        <FontAwesomeIcon 
                        icon={tag[2] != null ? tag[2] : undefined} 
                        size="1x" 
                        color={tag[1]}
                        style={{ 
                          maxHeight: '8px',
                          maxWidth: '8px',
                          marginRight: tag[2] != null ? "0.1em" : 0 
                          }}/>
                        <b style={{
                          color: tag[1],
                          fontSize: "0.8em",
                        }}>
                          {tag[0]}
                        </b>
                      </div>
                    ))}
                    </div>
                  </div>
                </div>
              ))
            }
        </div>
    </div>
  );
}

export default ResumeSkill;