import React, { useRef, useState } from 'react';
import '../style/App.css'
import myProfile from '../assets/img/profile.png';
import FadeInSection from '../FadeInSections'
import Chip from '@material-ui/core/Chip';
import { Button } from '@material-ui/core';

import CircularProgress from '@mui/material/CircularProgress';
import { PDFExport } from '@progress/kendo-react-pdf';
import ResumeExport from './ResumeBuilder/ResumeExport';

function About(props) {
  var documentRef = useRef(null);
  const [pdfGenerating, setPdfGenerating] = useState(false);
  const [downloadTriggered, setDownloadTriggered] = useState(false);
  const [loadProgress, setLoadProgress] = useState(0);
  const awsDownloadEnabled = true;
  const awsResumeUrl = "https://kenif.s3.ap-southeast-1.amazonaws.com/resume_230201.pdf";
  const blogUrl = "https://blog.kenif.xyz/";
  const resumeMode = props.resumeMode;
  
  return  (
    <div className="container" id="about">
      {
        resumeMode &&
      < div
        style={{
          top: 0,
        }}
      >
      <PDFExport paperSize={'A4'}
          fileName="resume.pdf"
          title=""
          subject=""
          keywords=""
          forcePageBreak=".page-break"
          ref={(r) => documentRef = r}>
            <ResumeExport/>
      </PDFExport></div>
      }
      {
        !resumeMode &&
      <FadeInSection>
      <div className="about-section">
        <div className="content" >
          <h1>About Me</h1>
          <p>Hey there! I'm Kenneth, a Computer Science grad from Singapore Institute of Technology and the University of Glasgow. As a Backend Engineer at Quilt.AI, I'm bridging technology and human understanding by using data to decode cultural insights. Constantly working on whatever makes things work better - check out my pet projects below!</p>

          {/* <p>Additionally, I am the founder of <a href='https://botpowered.sg/' target="_blank">BotPowered Solutions</a>, a software solutions company that has launched multiple services, catering to the needs of thousands of Singaporeans on a daily basis.</p> */}
          
          {/* <p>New in 2023: I've recently started a blog to share my experience with working on coding pet projects.</p> */}
      {
        pdfGenerating ?
        <div className="pdf-generating">
          <div className="progress-bar">
            <CircularProgress variant="indeterminate" />
          </div>
          <h5>Downloading PDF...</h5>
        </div>
        :
        downloadTriggered ?
        <div>
        <h5>Dynamic Download Initiated. Having Issues?</h5>
        <Button variant="contained" size="small">Download Static Resume</Button>
        </div>
        : resumeMode && 
        <Button variant="contained" size="small"
        onClick={() => {
          setPdfGenerating(true)
          documentRef.save()

          setPdfGenerating(false)
          setDownloadTriggered(true)
          // set timeout 1 second
          // setTimeout(() => {
          //   setPdfGenerating(false)
          //   setDownloadTriggered(true)
          // }, 1300)
        }}>Download Resume</Button>
      }
      { awsDownloadEnabled &&
      <div style={{ width: '100%', display: 'flex', justifyContent: 'left' }}>
      {/* <a href={blogUrl} target="_blank" rel="noreferrer" style={{ marginRight: "1em" }}>
        <Button variant="contained" 
        style={{ backgroundColor: '#305C5F', color: '#DDE1DC' }} 
        size="medium">Read Blog</Button>
        </a>  */}

        {/* <a href={awsResumeUrl} target="_blank" rel="noreferrer" style={{ marginRight: "1em" }}>
          <Button variant="contained" 
          style={{ backgroundColor: '#305C5F', color: '#DDE1DC' }} 
          size="medium">View Resume</Button>
          </a>  */}
        {/* <a href={awsResumeUrl} target="_blank" rel="noreferrer"><Button variant="outlined" size="medium">View Resume</Button></a>  */}
      </div>
      }
        </div>
        <div className="image-wrapper">
          <img src={myProfile} alt="myProfile" style={{
            borderRadius: "1.5em",
          }}/>
        </div>
      </div>
      </FadeInSection>
      }
    </div>
  );
}

export default About;